/**
 * https://github.com/leerob/daydrink/blob/master/pages/_error.js
 * Handles server side errors - 404, 500
 * Handles client side error - yet to test
 */
import _get from 'lodash/get';

import * as Sentry from '@sentry/nextjs';

import SEOTitle from '@/components/SeoTitle';
import ErrorContainer from '@/containers/ErrorContainer';
import PageNotFoundContainer from '@/containers/PageNotFoundContainer';

export default function Error({ statusCode, pageSEOMetaData, isRtl }) {
  console.log('** Error page statusCode: ', statusCode, isRtl);
  return (
    <>
      <SEOTitle pageSEOMetaData={pageSEOMetaData} />
      {statusCode === 404 ? (
        <PageNotFoundContainer />
      ) : (
        <ErrorContainer isRtl={isRtl} />
      )}
    </>
  );
}

/**
 * Runs on server side & client side
 */
Error.getInitialProps = async (contextData) => {
  const { store, res, err } = contextData;
  const pageSEOMetaData = store.appStore.config.getPageSEOMetaData('404');
  const isRtl = _get(store, 'appStore.config.isRtl', false);

  // In case this is running in a serverless function, await this in order to give Sentry
  // time to send the error before the lambda exits
  // await Sentry.captureUnderscoreErrorException(contextData);

  let statusCode = null;

  if (res) {
    statusCode = res.statusCode;
  } else if (err) {
    statusCode = err.statusCode;
  } else {
    statusCode = 404;
  }
  return { statusCode, pageSEOMetaData, isRtl };
};
