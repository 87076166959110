import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import navigateAndScrollToTop from '@lumirental/lumi-web-shared/lib/utils/lang/navigateAndScrollToTop';

import styles from '@/containers/PageNotFoundContainer/PageNotFoundContainer.style';

const useStyles = makeStyles()(styles);

export default function PageNotFoundContainer({ hideCta }) {
  const { classes } = useStyles();
  const router = useRouter();
  const { t } = useTranslation();

  const goToHomePage = () => {
    // redirect to home page
    navigateAndScrollToTop(router, '/');
  };

  return (
    <Container maxWidth="lg">
      <Grid item xs={12}>
        <div className={classes.errorContainer}>
          <div className={classes.mainWrapper}>
            <Typography variant="subtitle1" className={classes.headerTitle}>
              {t('page_not_found_title')}
            </Typography>
            <div className={classes.notFoundImg}>
              <Image
                src="/images/svg/page-not-found-img.svg"
                alt="Page Not Found"
                width={250}
                height={155}
              />
            </div>
            <div className={classes.contentWrapper}>
              <Typography variant="subtitle1" className={classes.title}>
                {t('cant_move_forward')}
              </Typography>
              <Typography variant="body2" className={classes.subTitle}>
                {t('cant_find_page_msg')}
              </Typography>
            </div>
            {!hideCta && (
              <div className={classes.btnWrapper}>
                <Button
                  variant="contained"
                  className={classes.backBtn}
                  onClick={goToHomePage}
                >
                  {t('back_to_home')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Grid>
    </Container>
  );
}

PageNotFoundContainer.propTypes = {
  hideCta: PropTypes.bool,
};

PageNotFoundContainer.defaultProps = {
  hideCta: false,
};
